import { datadogRum } from '@datadog/browser-rum'
import { ITenant, useUserConfig, useUserInfo } from '@valiot/user-auth'
import { get } from 'lodash'
import { useEffect } from 'react'
import packageInfo from '../package.json'

datadogRum.init({
  applicationId: 'd3b0a04a-c980-419d-908e-a72f63bd4bde',
  clientToken: 'pub82996a59a438c53c86cd6d3a02e397a1',
  site: 'datadoghq.com',
  service: 'acme',
  // env: '<ENV_NAME>',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: packageInfo.version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})
export const DatadogRum = () => {
  const user = useUserInfo()
  const { config, loading } = useUserConfig('facilities')

  useEffect(() => {
    if (config && loading === false) {
      const facilities: ITenant[] | undefined = get(config, 'configuration.facilities')
      const selectedFacilities = facilities && facilities.filter((plant) => plant.selected)?.map((plant) => plant.name)

      datadogRum.setUser({
        id: user.id,
        name: `${user.name} ${user.lastName}`,
        email: user.email,
        selectedFacilities,
      })
    } else {
      datadogRum.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        selectedFacilities: ['none'],
      })
    }

    datadogRum.startSessionReplayRecording()

    return () => {
      datadogRum.stopSessionReplayRecording()
    }
  }, [config, loading, user.email, user.id, user.lastName, user.name])

  return null
}
