import { ProjectSettings } from '@valiot/settings'
import packageJson from '../package.json'
const settings: ProjectSettings = {
  version: packageJson.version,
  title: 'ACME',
  copilotEnabled: true,
  api: {
    http: import.meta.env.VITE_API_URL,
    wss: import.meta.env.VITE_API_WSS,
    valiotWorker: import.meta.env.VITE_WORKER,
    ssoUrl: import.meta.env.VITE_SSO_URL,
    routerCode: import.meta.env.VITE_ROUTER_CODE,
  },
  products: 'ValueChainOS',
  analytics: {
    enabled: true,
    siteId: import.meta.env.VITE_MATOMO_SITE_ID,
  },
  locale: {
    default: 'en',
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  },
  support: {
    enabled: false,
  },
  tenancy: { enabled: true, selectionMode: 'one' },
  kioskMode: {
    enabled: false,
  },
  sidebarDefault: 'expanded',
  home: 'Test',
  routes: [
    {
      path: 'test',
      text: 'test',
      iconSource: 'fas',
      icon: 'fire',
      component: 'Test',
      sidebarDefault: 'expanded',
    },
    {
      path: 'conditional-render-machine',
      text: 'ConditionalRenderMachine',
      iconSource: 'fas',
      icon: 'project-diagram',
      component: 'SandBox',
      hidden: true,
    },
  ],
  dropdowns: [],
  sections: [
    {
      path: 'value-chain-os-demos',
      title: 'ValueChainOS-Demos',
      directory: 'value-chain-os-demos',
      routes: [],
      dropdowns: [
        {
          text: 'RecipesPage',
          directory: 'RecipesPage',
          path: 'recipes-page',
          icon: 'utensils',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'recipes-of-a-product',
              text: 'Recipes of a product',
              iconSource: 'fas',
              icon: 'project-diagram',
              component: 'RecipesOfAProduct',
              permissions: 'all',
              hidden: true,
            },
            {
              path: 'recipes-of-materials',
              text: 'RecipesOfMaterials',
              iconSource: 'fas',
              icon: 'project-diagram',
              component: 'RecipesOfMaterialsDemo',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          text: 'RelationsPage',
          directory: 'RelationsPage',
          path: 'relations-page',
          icon: 'users',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              // required icon
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'process-resource',
              text: 'ProcessResource',
              iconSource: 'fas',
              icon: 'tools',
              component: 'ProcessResource',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'resource-material',
              text: 'ResourceMaterial',
              iconSource: 'fas',
              icon: 'tools',
              component: 'ResourceMaterial',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'material-material',
              text: 'MaterialMaterial',
              iconSource: 'fas',
              icon: 'tools',
              component: 'MaterialMaterial',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'options-config',
              text: 'OptionsConfig',
              iconSource: 'fas',
              icon: 'cogs',
              component: 'OptionsConfig',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          text: 'ChangeoversPage',
          directory: 'ChangeoversPage',
          path: 'changeovers-page',
          icon: 'exchange-alt',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          text: 'StopsPage',
          directory: 'StopsPage',
          path: 'stops-page',
          icon: 'stop',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              exactUrl: false, // Important: Exact url always false for stops page
              hidden: false,
            },
            {
              path: 'tenancy',
              text: 'Tenancy',
              iconSource: 'fas',
              icon: 'globe-americas',
              component: 'Tenancy',
              permissions: 'all',
              exactUrl: false, // Important: Exact url always false for stops page
              hidden: false,
            },
            {
              path: 'manage-reasons',
              text: 'ManageReasons',
              iconSource: 'fas',
              icon: 'cogs',
              component: 'ManageReasonsScreen',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
          ],
        },
        {
          text: 'DemandPage',
          directory: 'DemandPage',
          path: 'demand-page',
          icon: 'shopping-cart',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'copy-snapshot',
              text: 'CopySnapshot',
              iconSource: 'fas',
              icon: 'copy',
              component: 'CopySnapshot',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'tenancy',
              text: 'Tenancy',
              iconSource: 'fas',
              icon: 'globe-americas',
              component: 'Tenancy',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'page-config',
              text: 'PageConfig',
              iconSource: 'fas',
              icon: 'cogs',
              component: 'PageConfig',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          text: 'InventoryPage',
          directory: 'InventoryPage',
          path: 'inventory-page',
          icon: 'warehouse',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              exactUrl: true,
              hidden: false,
            },
            {
              path: 'copy-snapshot-demo',
              text: 'CopySnapshot',
              iconSource: 'fas',
              icon: 'copy',
              component: 'CopySnapshot',
              permissions: 'all',
              exactUrl: true,
              hidden: false,
            },
            {
              path: 'tenancy',
              text: 'Tenancy',
              iconSource: 'fas',
              icon: 'globe-americas',
              component: 'Tenancy',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'custom-filters',
              text: 'CustomFilters',
              iconSource: 'fas',
              icon: 'filter',
              component: 'CustomFilters',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          text: 'PlanningList',
          directory: 'PlanningList',
          path: 'planning-list-page',
          icon: 'list',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'tenancy',
              text: 'Tenancy',
              iconSource: 'fas',
              icon: 'globe-americas',
              component: 'Tenancy',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'header-buttons',
              text: 'HeaderButtons',
              iconSource: 'fas',
              icon: 'list',
              component: 'HeaderButtons',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'range-of-dates',
              text: 'RangeOfDates',
              iconSource: 'fas',
              icon: 'calendar-alt',
              component: 'RangeOfDates',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'movable-plans',
              text: 'MovablePlans',
              iconSource: 'fas',
              icon: 'calendar-alt',
              component: 'MovablePlans',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          text: 'PlanningTimeline',
          directory: 'PlanningTimeline',
          path: 'planning-timeline-page',
          icon: 'calendar-alt',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'tenancy',
              text: 'Tenancy',
              iconSource: 'fas',
              icon: 'globe-americas',
              component: 'Tenancy',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'header-buttons',
              text: 'HeaderButtons',
              iconSource: 'fas',
              icon: 'list',
              component: 'HeaderButtons',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'movable-plans',
              text: 'MovablePlans',
              iconSource: 'fas',
              icon: 'calendar-alt',
              component: 'MovablePlans',
              permissions: 'all',
            },
            {
              path: 'timeline-customization',
              text: 'Timeline',
              iconSource: 'fas',
              icon: 'calendar-alt',
              component: 'Timeline',
              permissions: 'all',
            },
            {
              path: 'content-config',
              text: 'ContentConfig',
              iconSource: 'fas',
              icon: 'calendar-alt',
              component: 'ContentConfig',
              permissions: 'all',
            },
          ],
        },
        {
          text: 'SettingsPage',
          directory: 'SettingsPage',
          path: 'settings-page',
          icon: 'cog',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'tenancy',
              text: 'Tenancy',
              iconSource: 'fas',
              icon: 'globe-americas',
              component: 'Tenancy',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'groups',
              text: 'Groups',
              iconSource: 'fas',
              icon: 'users',
              component: 'Groups',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'columns-types',
              text: 'ColumnsTypes',
              iconSource: 'fas',
              icon: 'columns',
              component: 'ColumnsTypes',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'material-settings',
              text: 'MaterialSettings',
              iconSource: 'fas',
              icon: 'columns',
              component: 'MaterialSettings',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          text: 'PropertiesPage',
          directory: 'PropertiesPage',
          path: 'properties-page',
          icon: 'clipboard-list',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'categories',
              text: 'Categories',
              iconSource: 'fas',
              icon: 'clipboard-list',
              component: 'Categories',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'eliotsdll',
              text: 'Eliot_SDLL',
              iconSource: 'fas',
              icon: 'clipboard-list',
              component: 'Eliot_SDLL',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'properties',
              text: 'Properties',
              iconSource: 'fas',
              icon: 'clipboard-list',
              component: 'Properties',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'units',
              text: 'Units',
              iconSource: 'fas',
              icon: 'clipboard-list',
              component: 'Units',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          text: 'MonitorsPage',
          directory: 'MonitorsPage',
          path: 'monitors-page',
          icon: 'monitor-heart-rate',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'BasicDemo',
              permissions: 'all',
            },
          ],
        },
        {
          path: 'value-chain-os-provider',
          text: 'ValueChainOSProvider',
          directory: 'ValueChainOSProviderExamples',
          icon: 'cog',
          iconSource: 'fas',
          routes: [
            {
              path: 'inventory-page',
              text: 'InventoryPage',
              iconSource: 'fas',
              icon: 'warehouse',
              component: 'InventoryPage',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'planning-timeline',
              text: 'PlanningTimeline',
              iconSource: 'fas',
              icon: 'calendar-alt',
              component: 'PlanningTimeline',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'greedy-timeline',
              text: 'GreedyTimeline',
              iconSource: 'fas',
              icon: 'calendar-alt',
              component: 'GreedyTimeline',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'hjobshop-timeline',
              text: 'HJobshopTimeline',
              iconSource: 'fas',
              icon: 'calendar-alt',
              component: 'HJobshopTimeline',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'sequencer-timeline',
              text: 'SequencerTimeline',
              iconSource: 'fas',
              icon: 'calendar-alt',
              component: 'SequencerTimeline',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          text: 'KpiPage',
          directory: 'KpiPage',
          path: 'kpi-page',
          icon: 'monitor-heart-rate',
          iconSource: 'fas',
          routes: [
            {
              path: 'basic-demo',
              text: 'BasicDemo',
              iconSource: 'fas',
              icon: 'tools',
              component: 'KpiDemo',
              permissions: 'all',
            },
          ],
        },
      ],
    },
    {
      path: 'admin',
      title: 'Administración',
      directory: 'admin',
      routes: [],
      dropdowns: [
        {
          text: 'permisos',
          directory: 'permissions',
          path: 'permissions',
          icon: 'key',
          iconSource: 'fas',
          routes: [
            {
              path: 'users',
              text: 'Users',
              iconSource: 'fas',
              icon: 'user',
              component: 'Users',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'groups',
              text: 'Groups',
              iconSource: 'fas',
              icon: 'users',
              component: 'Groups',
              permissions: 'all',
              exactUrl: false,
              hidden: false,
            },
            {
              path: 'tenancy-admin',
              text: 'Tenancy',
              iconSource: 'fas',
              icon: 'globe-americas',
              component: 'TenancyAdmin',
              exactUrl: false,
              hidden: false,
            },
          ],
        },
      ],
    },
  ],
}

export default settings
